import girl from "./../../assets/img/girl.png";
import boy from "./../../assets/img/boy.png";

export const TestimonialsData = [
  {
    img: girl,
    comment:
      "Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum.",
    name: "John Alendon",
  },
  {
    img: boy,
    comment:
      "Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum.",
    name: "Natasha",
  },
  {
    img: girl,
    comment:
      "Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum.",
    name: "Alex Hormonzi",
  },
  {
    img: girl,
    comment:
      "Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum.",
    name: "John Alendon",
  },
  {
    img: boy,
    comment:
      "Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum.",
    name: "Natasha",
  },
  {
    img: girl,
    comment:
      "Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum.",
    name: "Alex Hormonzi",
  },
];
