import React, { useState } from "react";
import "./header.css";
import Logo from "./../../assets/img/amazon.png";
import shoppingbag from "./../../assets/img/shoppingbag.png";
import menu from "./../../assets/img/icon/menu.png";

function Header() {
  const [showMenu, setShowMenu] = useState(true);

  const toggleMenu = () => {
    setShowMenu((showMenu) => !showMenu);
  };

  return (
    <div className="header-body">
      <div className="logo-div">
        <img className="logo" src={Logo} alt="" />
        <span className="logo-span">amazon</span>
      </div>

      <div className="right-items">
        <div className="bars">
          <img src={menu} onClick={toggleMenu} className="menu-icon" alt="" />
        </div>

        <ul
          className="menu-ul"
          style={{ display: showMenu ? "inherit" : "none" }}
        >
          <li className="menu-li">Collections</li>
          <li className="menu-li">Brands</li>
          <li className="menu-li">New</li>
          <li className="menu-li">Sales</li>
          <li className="menu-li">ENG</li>
        </ul>

        <input type="text" className="search-box" placeholder="Search" />
        <img src={shoppingbag} className="cart-img" alt="" />
      </div>
    </div>
  );
}

export default Header;
