import React from "react";
import "./virtual.css";
import ReactCompareImage from "react-compare-image";
import shade from "./../../assets/img/shade.png";
import Before from "./../../assets/img/before.png";
import After from "./../../assets/img/after.png";

function Virtual() {
  return (
    <div className="virtual-body">
      <div className="left-virt">
        <span className="left-first-ch">Virtual Try-on</span>
        <span className="left-scnd-ch">Never Buy The Wrong Shade Again!</span>
        <span className="left-third-ch">Try Now!</span>
        <img className="shade-png" src={shade} alt="" />
      </div>
      <div className="right-virt">
        <div className="wrapper-virt">
           <ReactCompareImage leftImage={Before} rightImage={After} /> 
        </div>
        
      </div>
    </div>
  );
}

export default Virtual;
