import "./App.css";
import Header from "./component/header/Header";
import Hero from "./component/hero/Hero";
import Slider from "./component/slider/Slider";
import Virtual from "./component/Virtual/Virtual";
import Product from "./component/Product/Product";
import TestMonials from "./component/TestMonials/TestMonials";
import Footer from "./component/Footer/Footer";

function App() {
  return (
    <div className="app-body">
      <Header />
      <Hero />
      <Slider />
      <Virtual />
      <Product />
      <TestMonials />
      <Footer />
    </div>
  );
}

export default App;
