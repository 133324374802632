import React from "react";
import "./testmonials.css";
import hero2 from "./../../assets/img/hero2.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { TestimonialsData } from "./TestimonialsData";

function TestMonials() {
  return (
    <div className="testi-container">
      <div className="wrapper">
        <div className="contaier1">
          <span className="wrap-first">TOP RATED</span>
          <span className="wrap-scnd">
            SEEDILY SAY HAS SUITABLE DISPOSAL AND BOY. EXERCISE JOY MAN CHILDREN
            REJOICED.
          </span>
        </div>

        <img src={hero2} alt="" className="hero2-img" />
        <div className="container2">
          <span className="right-first">100K</span>
          <span className="right-scnd">HAPPY CUSTOMERS WITH US</span>
        </div>
      </div>
      <div className="review">Reviews</div>
      <div className="carousal">
        <Swiper
          slidesPerView={3}
          slidesPerGroup={1}
          spaceBetween={20}
          className="tCarousel"
          breakpoints={{
            856: {
              slidesPerView: 3,
            },
            640: {
              slidesPerView: 2,
            },
            0: {
              slidesPerView: 1,
            },
          }}
        >
          {TestimonialsData.map((testim, i) => (
            <SwiperSlide>
              <div key={i} className="testi-cart">
                <img src={testim.img} alt="" className="testi-cart-img" />
                <span className="testi-commnt"> {testim.comment}</span>
                <hr className="testi-hr" />
                <span className="testi-cart-name">{testim.name}</span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default TestMonials;

<Swiper>
  {TestimonialsData.map((testim, i) => (
    <SwiperSlide>
      <div key={i} className="testi-cart">
        <img src={testim.img} alt="" className="testi-cart-img" />
        <span className="testi-commnt"> {testim.comment}</span>
        <hr />
        <span className="testi-cart-name">{testim.name}</span>
      </div>
    </SwiperSlide>
  ))}
</Swiper>;
