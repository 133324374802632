import React from "react";
import "./slider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css/bundle";

// swiper core styles
import "swiper/css";

// modules styles
import "swiper/css/navigation";
import "swiper/css/pagination";
// import { SliderProducts } from "./../Product/Product";
import img1 from "./../../assets/img/cstmtic/1pic.png";
import img2 from "./../../assets/img/cstmtic/2pic.png";
import img3 from "./../../assets/img/cstmtic/3pic.png";
import img4 from "./../../assets/img/cstmtic/4pic.png";

function Slider() {
  const SliderProducts = [
    {
      name: "SKIN",
      detail: "Super Skin Care",
      price: "25",
      img: img1,
    },
    {
      name: "SKIN",
      detail: "Super Skin Care",
      price: "30",
      img: img2,
    },
    {
      name: "SKIN",
      detail: "Super Skin Care",
      price: "35",
      img: img3,
    },
  ];

  const itemList = ["Item1", "Item2", "Item3", "Item4", "Item5"];
  const array = ["Item1", "Item2", "Item3", "Item4", "Item5"];

  return (
    <div className="s-container">
      <Swiper
        breakpoints={{
          640: {
            slidesPerView: 3,
          },
          0: {
            slidesPerView: 1,
          },
        }}
        modules={[Pagination, Navigation]}
        className="MySwiper"
        loopFillGroupWithBlank={true}
        navigation={true}
        slidesPerView={3}
        spaceBetween={40}
        slidesPerGroup={1}
        loop={true}
      >
        {SliderProducts.map((slide, i) => (
          <SwiperSlide>
            <div key={i} className="left-s">
              <div className="name">
                <span className="name-first">{slide.name}</span>
                <span className="scnd-text">{slide.detail}</span>
              </div>
              <span className="price-sl">{slide.price} $</span>
              <div className="shop-btn">Shop Now</div>
            </div>
            <img src={slide.img} alt="product" className="img-p" />
          </SwiperSlide>
        ))}

        {/* {SliderProducts.map((slide, i) => {
          <SwiperSlide key={i}>{slide.name}</SwiperSlide>;
        })} */}
      </Swiper>
      {SliderProducts.price}
    </div>
  );
}

export default Slider;
