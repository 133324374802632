import React from "react";
import "./hero.css";
import hero from "./../../assets/img/hero.png";
import right from "./../../assets/img/right.png";
import shoppingbag from "./../../assets/img/bag.png";
import { motion } from "framer-motion";

function Hero() {
  const transition = { duration: 3, type: "sprig" };

  return (
    <div className="hero-container">
      {/* left side */}
      <div className="h-sides">
        <div className="left-top-items">SKIN PROTECTION CREAM</div>
        <div className="left-bottem-texts">
          <div className="big-text">Tredy Collection</div>
          <div className="left-text">
            Seedly say has suitable disposal and boy.Exercise joy man children
            rejoiced.
          </div>
        </div>
      </div>

      {/* middle side */}
      <div className="middle-side">
        <motion.div
          initial={{ bottom: "2rem" }}
          whileInView={{ bottom: "0rem" }}
          className="blue-circle"
          transition={transition}
        ></motion.div>

        <motion.img
          transition={transition}
          initial={{ bottom: "-2rem" }}
          whileInView={{ bottom: "0rem" }}
          className="hero-img"
          src={hero}
          alt=""
        />
        <motion.div
          transition={transition}
          initial={{ right: "4%" }}
          whileInView={{ right: "2%" }}
          className="cart-2"
        >
          <img className="shopping-bag-icon" src={shoppingbag} alt="" />
          <div className="signup">
            <span className="signup-first-ch">Best Signup Offers</span>
            <img className="arrow-icon" src={right} alt="" />
          </div>
        </motion.div>
      </div>
      {/* right side */}
      <div className="h-sides">
        <div className="traffics">
          <span className="trfic-first">1.5m</span>
          <span className="trfic-scnd">Monthly Traffic</span>
        </div>
        <div className="customers">
          <span className="cust-first">100k</span>
          <span className="cust-scnd">Happy Customers</span>
        </div>
      </div>
    </div>
  );
}

export default Hero;
