import React from "react";
import "./footer.css";
import logo from "./../../assets/img/amazon.png";
// import {
//   InboxIcon,
//   PhoneIcon,
//   LocationMarkerIcon,
//   LoginIcon,
//   UserIcon,
//   LinkIcon,
// } from "@heroicons/react/outline";
import loc from "./../../assets/img/icon/loc.png";
import phone from "./../../assets/img/icon/phone.png";
import email from "./../../assets/img/icon/email.png";
import signin from "./../../assets/img/icon/sign.png";
import aboutus from "./../../assets/img/icon/abs.png";
import privacy from "./../../assets/img/icon/safe.png";

function Footer() {
  return (
    <div className="footerwrapper">
      <hr className="footer-hr" />
      <div className="cfooter">
        <div className="logo">
          <img src={logo} alt="" className="amazo-logo" />
          <span className="logo-name">amazon</span>
        </div>
        <div className="block">
          <div className="details">
            <span className="det-spn">Contact Us</span>
            <div className="pngline">
              <img src={loc} className="icon" />
              <span className="icon-text">
                111 north avenue Orlando, FL 32801
              </span>
            </div>
            <div className="pngline">
              <img src={phone} className="icon" />
              <span className="icon-text">352-306-4415</span>
            </div>
            <div className="pngline">
              <img src={email} className="icon" />
              <span className="icon-text">support@amazon.com</span>
            </div>
          </div>
        </div>
        <div className="block">
          <div className="details">
            <span className="det-spn">Account</span>
            <div className="pngline">
              <img src={signin} className="icon" />
              <span className="icon-text">Sign In</span>
            </div>
          </div>
        </div>
        <div className="block">
          <div className="details">
            <span className="det-spn">Company</span>
            <div className="pngline">
              <img src={aboutus} className="icon" />
              <span className="icon-text">About Us</span>
            </div>
          </div>
        </div>
        <div className="block">
          <div className="details">
            <span className="det-spn">Resources</span>
            <div className="pngline">
              <img src={privacy} className="icon" />
              <span className="icon-text">Safety Privacy & Terms</span>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <span>Copyright ©2022 by Amazon, Inc.</span>
        <span>All rights reserved.</span>
      </div>
    </div>
  );
}

export default Footer;
