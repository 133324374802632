import React, { useState } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import heart from "./../../assets/img/heart.png";
import "./product.css";
import { ProductsData } from "./ProductsData/ProductsData";

function Product() {
  const [parent] = useAutoAnimate();

  const [menuProducts, setMenuProducts] = useState(ProductsData);

  const Filter = (type) => {
    setMenuProducts(ProductsData.filter((product) => product.type === type));
  };

  return (
    <div className="p-cotainer">
      <img className="heart-png" src={heart} alt="" />
      <h1 className="h1-text">Our Featured Products</h1>
      <div className="products">
        <ul className="menu">
          <li onClick={() => setMenuProducts(ProductsData)}>All</li>
          <li onClick={() => Filter("skin care")}>Skin Care</li>
          <li onClick={() => Filter("Conditioner")}>Conditioners</li>
          <li onClick={() => Filter("Foundation")}>Foundation</li>
        </ul>
        <div className="list" ref={parent}>
          {menuProducts.map((product, i) => (
            <div className="product-itm">
              <div className="left-s">
                <div className="name">
                  <span className="prod-name">{product.name}</span>
                  <span className="prod-detail">{product.detail}</span>
                </div>
                <span className="price-prod">{product.price} $</span>
                <div className="shop-prod">Show Now</div>
              </div>
              <img src={product.img} alt="" className="img-p" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Product;
