import img1 from "./../../../assets/img/cstmtic/1pic.png";
import img2 from "./../../../assets/img/cstmtic/2pic.png";
import img3 from "./../../../assets/img/cstmtic/3pic.png";
import img4 from "./../../../assets/img/cstmtic/4pic.png";
import img5 from "./../../../assets/img/cstmtic/5pic.png";
import img6 from "./../../../assets/img/cstmtic/6pic.png";
import img7 from "./../../../assets/img/cstmtic/7pic.png";
import img8 from "./../../../assets/img/cstmtic/8pic.png";
import img9 from "./../../../assets/img/cstmtic/9pic.png";
import img10 from "./../../../assets/img/cstmtic/10pic.png";
import img11 from "./../../../assets/img/cstmtic/11pic.png";
import img12 from "./../../../assets/img/cstmtic/12pic.png";
import img13 from "./../../../assets/img/cstmtic/13pic.png";
import img14 from "./../../../assets/img/cstmtic/14pic.png";

export const ProductsData = [
  {
    name: "Skin",
    detail: "Super Skin Care",
    price: "25",
    img: img1,
    type: "skin care",
  },
  {
    name: "Skin",
    detail: "Super Skin Care",
    price: "35",
    img: img2,
    type: "skin care",
  },
  {
    name: "Skin",
    detail: "Super Skin Care",
    price: "25",
    img: img3,
    type: "skin care",
  },
  {
    name: "NATURE",
    detail: "Super Skin Care",
    price: "30",
    img: img4,
    type: "Foundation",
  },
  {
    name: "Conditioner",
    detail: "Skin Care",
    price: "25",
    img: img5,
    type: "Conditioner",
  },
  {
    name: "Skin",
    detail: "Skin Care",
    price: "25",
    img: img6,
    type: "'skin care",
  },
  {
    name: "Conditioner",
    detail: "Skin Care",
    price: "30",
    img: img7,
    type: "Conditioner",
  },
  {
    name: "Conditioner",
    detail: "Skin Care",
    price: "30",
    img: img8,
    type: "Conditioner",
  },
  {
    name: "Conditioner",
    detail: "Skin Care",
    price: "30",
    img: img9,
    type: "Conditioner",
  },
  {
    name: "Conditioner",
    detail: "Skin Care",
    price: "30",
    img: img10,
    type: "Conditioner",
  },
  {
    name: "Foundation",
    detail: "Skin Care",
    price: "12",
    img: img11,
    type: "Foundation",
  },
  {
    name: "Foundation",
    detail: "Skin Care",
    price: "12",
    img: img12,
    type: "Foundation",
  },
  {
    name: "Foundation",
    detail: "Skin Care",
    price: "12",
    img: img13,
    type: "Foundation",
  },
  {
    name: "Foundation",
    detail: "Skin Care",
    price: "12",
    img: img14,
    type: "Foundation",
  },
];
